import hoverintent from "./hover-intent.js";

export let hoverIntentDirective = {
    
    beforeMount( el, binding ) {
        /**
            You can use this by either passing a ref which points to a vue reactive,
            or a change function that acts as a callback.
            You can pass in a .capture modifier on the directive to capture events. 
        */
        let { change = null, options = {}, ref = null } = binding.value

        if( typeof change !== 'function' && ref === null ) {
            return
        }

        let handler = change ? change : ( newValue: boolean ) => {
            ref.value = newValue
        }

        if( typeof handler !== 'function' ) {
            return
        }

        el.$hoverintent = hoverintent(
            el,
            () => handler( true ),
            () => handler( false ),
            {
                ...options,
                ...binding.modifiers
            }
        ) 

    },

    beforeUnmount(el, binding) {

        if ( el.$hoverintent ) {
            el.$hoverintent.remove()
        }

    }

}
