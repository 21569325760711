/**
    any extensions to app sohuld be done in here.
 */

import { onClickOutside } from '@vueuse/core'
 
let createApp = (app: any, $pockets: $pockets) => { 
    app.directive( 'click-outside', onClickOutside )
}

let init = (app: any, $pockets: $pockets) => {  }

export let plugin:$pocketsPlugin = {
    init,
    createApp
}