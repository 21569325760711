
import { $pockets } from "@/pockets"
/**
    This is the boostrapper that looks for and initially adds them to the module list.
    This list is then used for extension loading, such as through the createApp or init hooks. 

    This can be externally extended via register, allowing external libraries to hook in their own extensions. 

    There's a big difference between how different bundlers allow wildcard inclusion.
    Therefor the $pockets.bundler variable is used to change how the plugins are loaded. 
*/

/**
    Webpack plugin loader cannot use stored instance due to promises.
*/
let loadPluginsWebPack = ( modules ) => {
    let context = require.context("@/", true, /\pockets-plugin.ts$/);
    return context.keys().map( (path: string) => {
        let plugin:$pocketsPlugin = context( path )?.plugin ?? {}
        return {
            ...plugin,
            path,
        }
    } )
}

let loadPluginsVite = ( modules ) => {
    return Object.entries( modules ).map( ( { 0: path, 1: module } ) => {
        //@ts-expect-error
        const plugin:$pocketsPlugin = module.plugin || {};
        return {
            ...plugin,
            path, 
        }
    } )
}

export let plugins = {
    modules: [],
    registered: [],
    async init(){
        if( $pockets.bundler == 'vite' ) {
            //@ts-expect-error
            plugins.modules = await import.meta.glob( '@/**/pockets-plugin.ts', { eager: true } )
        }
        let init = plugins.load('init')
        //@ts-expect-error
        init( false , $pockets )
    }, 
    //@ts-expect-errora
    add: ( plugin: $pocketsPlugin ) => plugins.registered.push(plugin),
    load(hookName: keyof $pocketsPlugin ){

        return async ( app: $pocketsApp, ...args:any[] ) => {
            
            let list;
            
            if( $pockets.bundler == 'webpack' ) {
                list = loadPluginsWebPack( plugins.modules ) 
            }
            if( $pockets.bundler == 'vite' ) {
                list = loadPluginsVite( plugins.modules )
            }

            if( app ) {
                app.config.globalProperties.$pockets = $pockets
            }
            
            let arr = [ ...plugins.registered, ...list ]

            arr.forEach( ( loader: $pocketsPlugin ) =>  {
                if( hookName in loader == false) return;
                let fn = loader[hookName] ?? false
                if(typeof fn === 'function') fn( app, $pockets, ...args)
            } )

        }

    }

}

