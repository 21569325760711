import { $pockets } from "@/pockets"
import { useCrud } from "./use-crud"
import { defineAsyncComponent } from 'vue'

let component = defineAsyncComponent(() => import('./component/index.vue') )

export let plugin:$pocketsPlugin = {
    createApp( app: $pocketsApp, $pockets: $pockets){
        app.component('pockets-crud-connection', component)
    },
    init: ( app: $pocketsApp, $pockets: $pockets ) => { 
        $pockets.crud = useCrud( $pockets.data )
    }
}