import { $pockets } from "@/pockets/"
import { createApp } from "vue"

export let init = function() {

    $pockets.plugins.init()
    
    const event = new CustomEvent( "pockets-ready", {
        bubbles: true,
        detail: $pockets,
    } )

    document.body.dispatchEvent( event )

}