import { $pockets } from "@/pockets"

export let plugin:$pocketsPlugin = {

    createApp(app: $pocketsApp, $pockets: $pockets){

        app.directive('focus', {
            mounted(el) {
                el.focus()
            }
        } ) 

    },
    
}