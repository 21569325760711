
import { defineAsyncComponent } from "vue"

import Vue3Toasity, { type ToastContainerOptions } from 'vue3-toastify'
import { toast } from "vue3-toastify";
import 'vue3-toastify/dist/index.css';

let component = defineAsyncComponent( () => import("./index.vue") )

export let plugin:$pocketsPlugin = {
    createApp(app: any, $pockets: $pockets) {
        
        $pockets.toast = toast
        
        app.use( 
            Vue3Toasity,
            {
                autoClose: 3000,
                // ...
            } as ToastContainerOptions,
        )

        app.component('pockets-ux-toast', component)

    }
}
