
export class cacheMap {
    map = {}
    cachingEnabled = false
    set( k, v ){
        if( !this.cachingEnabled ) {
            return
        }
        this.map[ k ] = v
    }
    get( k ) {
        if( !this.cachingEnabled ) {
            return
        }
        return this.map[ k ]
    }
    delete(k){
        delete this.map[ k ]
    }
}
