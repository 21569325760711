// @ts-nocheck
import type { crudConnection, useCrudConfig } from "@/pockets/crud/types"
import { createConnection } from '@/pockets/connection/create-connection'
import { cacheMap } from "./cache-map"
import { AxiosRequestConfig } from 'axios';

export let useCrud = ( $config: useCrudConfig ) => {
    
    let { loader, api } = createConnection()

    loader._cacheKeyFn = key => JSON.stringify(key)
    loader._cacheMap = new cacheMap()

    api.interceptors.request.use( ( config: AxiosRequestConfig ) => {
        
        let headers = {
            ...config.headers,
        }
        
        if( $config['crud-resource'] ) {
            headers['crud-resource'] = JSON.stringify( $config['crud-resource'] )
        }
        
        if( $config.crud.nonce ) {
            headers['X-WP-Nonce'] = $config.crud.nonce
        }

        return {
            ...config,
            method: "post",
            url: $config.crud.url,
            headers
        }

    } )


    return ( model: string ) => {

        let createInit = init => {

            let actionGenerator = action => async( input, output ) => await loader.load( {
                action,
                model, 
                init,
                input,
                output
            } as crudConnection ) 

            return {
                create: actionGenerator( "create" ),
                read:   actionGenerator( "read" ),
                update: actionGenerator( "update" ),
                delete: actionGenerator( "delete" ),
            }

        }

        let initApi = {
            init: createInit,
            initOnce: init => {

                let $init = createInit(init)

                let handler = action => async( input, output ) =>  {

                    let { cachingEnabled } = loader._cacheMap
                    
                    /**
                        Enables cache.
                        Tries to fetch
                        if error, purges cached key so that it can be re-fetched
                        Finally sets cachingEnabled back to its original value.
                    */

                    loader._cacheMap.cachingEnabled = true

                    try {
                        let response = await $init[action]( input, output )
                        loader._cacheMap.cachingEnabled = cachingEnabled
                        return response
                    } catch(e) {
                        loader._cacheMap.delete( {
                            action,
                            model, 
                            init,
                            input,
                            output
                        } )
                        loader._cacheMap.cachingEnabled = cachingEnabled
                        return Promise.reject(e)
                    } 

                }
                
                return {
                    create: handler('create'),
                    read: handler('read'),
                    update: handler('update'),
                    delete: handler('delete')
                }

            }

        }

        return initApi

    }

}